import React, { useState, useEffect } from "react";
import { getClients, getReportFilters } from "../../functions/api";
import { LoadingOverlay, Title, ActionIcon, Select } from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";
import ClientTable from "../../Components/ClientTable";
import { Eye } from "tabler-icons-react";

const ClientOverviewScreen = () => {
  const intl = useIntl();
  const [filters, setFilters] = useState();
  const [perPage, setPerPage] = useState(40);

  useEffect(() => {
    document.title = `${intl.formatMessage({
      id: "client_overview_screen",
    })} - SOMECRM`;
  }, []);

  useEffect(() => {
    getReportFilters().then(async (x) => {
      setFilters(x);
    });
  }, []);

  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getClients().then((response) => {
      setClients(response);
      setIsLoading(false);
    });
  }, []);

  const getUserByID = (userid) => {
    const creator = filters?.users?.find((obj) => obj.value == userid);
    return creator?.label != undefined ? creator?.label : "";
  };

  const columns = [
    {
      key: "name",
      title: "name",
      searchable: true,
      sort: true,
      render: (client) => client.c_name,
    },
    {
      key: "surname",
      title: "surname",
      searchable: true,
      sort: true,
      render: (client) => client.c_surname,
    },
    {
      key: "email",
      title: "email",
      searchable: true,
      sort: true,
      render: (client) => client.c_email,
    },
    {
      key: "phone",
      title: "phone",
      searchable: true,
      sort: true,
      render: (client) => client.c_phone,
    },
    {
      key: "creation_data",
      title: "creation_data",
      sort: false,
      searchable: false,
      sort: true,
      render: (client) =>
        `${new Date(client.c_created).toLocaleString()} ${
          client.c_created_by ? getUserByID(client.c_created_by) : ""
        }`,
      // render: (client) => (
      //   <>
      //     <div>{(new Date(client.c_created).toLocaleString())}</div>
      //     <div>
      //       {client.c_created_by ? getUserByID(client.c_created_by) : ""}
      //     </div>
      //   </>
      // ),
    },
    {
      key: "action",
      title: "",
      searchable: false,
      render: (client) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a target="_blank" href={`/partner/${client.clientid}`}>
            <ActionIcon color="green" size="lg" variant="light">
              <Eye />
            </ActionIcon>
          </a>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title order={2}>
            <FormattedMessage id="client_overview" />
          </Title>

          <Select
            style={{ marginTop: -20 }}
            value={perPage}
            size="xs"
            onChange={(x) => setPerPage(x)}
            data={[
              { value: 40, label: 40 },
              { value: 80, label: 80 },
              { value: 100, label: 100 },
              { value: 200, label: 200 },
              { value: 500, label: 500 },
            ]}
            label={<FormattedMessage id="records_per_page" />}
          />
        </div>
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <div
            style={{
              marginTop: 20,
              position: "relative",
              height: isLoading ? 600 : "auto",
            }}
          >
            <LoadingOverlay visible={isLoading} overlayBlur={2} />

            <ClientTable
              clients={clients}
              columns={columns}
              itemsPerPage={perPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientOverviewScreen;
