import React from "react";
import { Clock, User, CurrencyDollar, Calendar } from "tabler-icons-react";
import { TimeDiffToDisplay } from "../functions/misc";
import {
  useMantineTheme,
  Text,
  Button,
  createStyles,
  Badge,
} from "@mantine/core";
import PinButton from "./Buttons/PinButton";
import { FormattedMessage, useIntl } from "react-intl";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength - 3) + '...';
};

const useStyles = createStyles((theme) => ({
  fadeOut: {
    position: "relative",
    overflowY: "auto",
  },
  fadeOutOverlay: {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 50,
    paddingRight: 50,
    background:
      "linear-gradient(to right, rgba(249, 250, 251, 0) 0%, rgba(249, 250, 251, 1) 100%)",
    pointerEvents: "none",
  },
}));

export const PinBar = ({ pinned }) => {
  const { classes } = useStyles();
  return (
    <div style={{ width: "100%", marginLeft: -5 }}>
      <div className={classes.fadeOut}>
        <div>
          <h1 style={{ paddingLeft: 10 }}>
            <FormattedMessage id="pinned" />
          </h1>
          <div
            id="pin-bar"
            style={{
              height: 130,
              overflow: "auto",
              whiteSpace: "nowrap",
              paddingRight: 50,
              paddingLeft: 10,
            }}
          >
            {pinned &&
              pinned.map((xp) => {
                if (xp.item_type == "client")
                  return <ClientPin key={xp.pinid} client={xp} />;
                if (xp.item_type == "task")
                  return <TaskPin key={xp.pinid} task={xp} />;
                if (xp.item_type == "invoice")
                  return <InvoicePin key={xp.pinid} invoice={xp} />;
                if (xp.item_type == "event")
                  return <EventPin key={xp.pinid} event={xp} />;
              })}
          </div>
        </div>
        <div className={classes.fadeOutOverlay}></div>
      </div>
    </div>
  );
};

// let rt =
// xp.item_type == "client" ? (
//   <ClientPin key={xp.pinid} client={xp} />
// ) : xp.item_type == "task" ? (
//   <TaskPin key={xp.pinid} task={xp} />
// ) : (
//   <InvoicePin key={xp.pinid} invoice={xp} />
// );
// // rt = xp.item_type == "task" ? <TaskPin task={xp} /> : null;
// return rt;

export const TaskPin = ({ task }) => {
  const intl = useIntl();
  const theme = useMantineTheme();

  const deadlineDate = new Date(task.deadline);
  const taskCreated = new Date(task.created);

  const isTaskCompleted = task.completed;
  const isTaskPastDeadline = deadlineDate < new Date();
  const taskBackgroundColor =
    isTaskPastDeadline && !isTaskCompleted
      ? theme.colors.red[1]
      : isTaskCompleted
      ? theme.colors.green[1]
      : theme.colors.gray[0];

  const timeDiff = deadlineDate - new Date();

  return (
    <div
      style={{
        display: "inline-block",
        height: 100,
        minWidth: 350,
        maxWidth: 350,
        margin: 5,
        backgroundColor: taskBackgroundColor,
        borderRadius: theme.radius.sm,
        boxShadow: `0px 4px 10px lightgrey`,
        padding: "0 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <Clock size={18} color={theme.colors.gray[7]} />
        <PinButton itemId={task.taskid} itemType={task.item_type} />
      </div>
      <div
        onClick={() => window.open("/partner/" + task.clientid, "_blank")}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: 10, overflow: "hidden" }}>
            <Text size={task.task_name.length > 50 ? "xs" : "sm"} weight={task.task_name.length > 50 ? 400 : 500} style={{ textWrap: "wrap" }}>
              {task.task_name}
            </Text>
            <Text
              size="xs"
              color={theme.colors.gray[7]}
              style={{ marginTop: 5}}
              
            >
              {task.c_name} {task.c_surname}
            </Text>
          </div>
        </div>
        <div style={{ display: "flex", paddingTop: 15}}>
          {!isTaskPastDeadline && (
            <Text
              size="xs"
              color={theme.colors.gray[7]}
              style={{ marginRight: 5 }}
            >
              {intl.formatMessage({ id: "due_in" })}
              {TimeDiffToDisplay(timeDiff)}
            </Text>
          )}
          {isTaskPastDeadline && (
            <Text
              size="xs"
              color={theme.colors.gray[7]}
              style={{ marginRight: 5 }}
            >
              {isTaskCompleted
                ? intl.formatMessage({ id: "completed" }) +
                  TimeDiffToDisplay(new Date(task.completed) - new Date()) +
                  " " +
                  intl.formatMessage({ id: "ago" })
                : intl.formatMessage({ id: "missed" }) +
                  TimeDiffToDisplay(timeDiff) +
                  " " +
                  intl.formatMessage({ id: "ago" })}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export const ClientPin = ({ client }) => {
  const theme = useMantineTheme();

  return (
    <div
      style={{
        display: "inline-block",
        height: 100,
        minWidth: 350,
        maxWidth: 350,
        margin: 5,
        borderRadius: theme.radius.sm,
        boxShadow: `0px 4px 10px lightgrey`,
        padding: "0 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <User size={18} color="dodgerblue" />
        <PinButton itemId={client.clientid} itemType={client.item_type} />
      </div>
      <div
        onClick={() => window.open("/partner/" + client.clientid, "_blank")}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: 10 }}>
            <Text size="sm" weight={500}>
              {client.c_name} {client.c_surname}
            </Text>
            <Text size="xs" style={{ marginTop: 5 }}>
              {client.c_phone}
            </Text>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Text size="xs" style={{ marginRight: 5 }}>
            {client.c_email}
          </Text>
        </div>
      </div>
    </div>
  );
};

export const InvoicePin = ({ invoice }) => {
  const intl = useIntl();
  const dueDate = new Date(
    invoice.extended_due ? invoice.extended_due : invoice.due_date
  );
  const isInvoicePaid = invoice.paid;
  const invoiceBackgroundColor = isInvoicePaid ? "#D1FAE5" : "#F3F4F6";
  const amountColor = isInvoicePaid ? "#047857" : "#1F2937";
  const paidButtonColor = isInvoicePaid ? "#047857" : "#4B5563";
  const paidButtonTextColor = isInvoicePaid ? "#FFFFFF" : "#1F2937";

  return (
    <div
      style={{
        display: "inline-block",
        height: 100,
        minWidth: 350,
        maxWidth: 350,
        margin: 5,
        backgroundColor: invoiceBackgroundColor,
        borderRadius: 6,
        boxShadow: "0px 4px 10px #E5E7EB",
        padding: "0 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <CurrencyDollar size={18} color="#4B5563" />
        <PinButton itemId={invoice.invoice_id} itemType={"invoice"} />
      </div>
      <div
        onClick={() => window.open("/invoices/" + invoice.invoice_id, "_blank")}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: 10 }}>
            <Text size="sm" weight={500}>
              {invoice.c_name} {invoice.c_surname}
            </Text>
            <Text size="xs" color="#4B5563" style={{ marginTop: 5 }}>
              {intl.formatMessage({ id: "amount" })}
              {invoice.total_vat}{" "}
              {invoice.paid_amount && "( paid: " + invoice.paid_amount + ")"}
              <span style={{ color: amountColor }}>{invoice.amount}</span>
            </Text>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isInvoicePaid ? (
            <Button
              size="xs"
              variant="outline"
              color={paidButtonColor}
              textColor={paidButtonTextColor}
              style={{ marginRight: 5 }}
            >
              <Text size="xs" weight={500}>
                {intl.formatMessage({ id: "pc_paid" })}
              </Text>
            </Button>
          ) : (
            <Text
              size="xs"
              color="#4B5563"
              style={{ marginRight: 5, textAlign: "right" }}
            >
              {intl.formatMessage({ id: "due_on" })}
              {dueDate.toLocaleDateString()}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export const EventPin = ({ event }) => {
  const intl = useIntl();
  const theme = useMantineTheme();

  const startDate = new Date(event.event_start_time);
  const endDate = new Date(event.event_end_time);
  // const taskCreated = new Date(event.created_by);

  const isTaskCompleted = endDate < new Date(); //task.completed;
  const isTaskPastDeadline = startDate < endDate;
  const taskBackgroundColor =
    isTaskPastDeadline && !isTaskCompleted
      ? theme.colors.red[1]
      : isTaskCompleted
      ? theme.colors.green[1]
      : theme.colors.gray[0];

  const timeDiff = startDate - new Date();

  return (
    <div
      style={{
        display: "inline-block",
        height: 100,
        minWidth: 350,
        maxWidth: 350,
        margin: 5,
        backgroundColor: taskBackgroundColor,
        borderRadius: theme.radius.sm,
        boxShadow: `0px 4px 10px lightgrey`,
        padding: "0 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Calendar size={18} color={theme.colors.gray[7]} />
            <Badge
              size="s"
              style={{ paddingLeft: 5, paddingRight: 5, marginLeft: 5 }}
              color={
                event?.event_type == "meeting"
                  ? "green"
                  : event?.event_type == "event"
                  ? "red"
                  : null
              }
            >
              {event?.event_type}
            </Badge>
          </div>
        </div>
        <PinButton itemId={event.event_id} itemType={event.item_type} />
      </div>
      <div
        onClick={() => window.open("/event/" + event.event_id, "_blank")}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: 10 }}>
            <Text size="sm" weight={500}>
              {event.event_title}
            </Text>
            <Text
              size="xs"
              color={theme.colors.gray[7]}
              style={{ marginTop: 5 }}
            >
              {new Date(event.event_start_time).toLocaleDateString()}{" "}
              {event.event_end_time &&
                ` - ${new Date(event.event_end_time).toLocaleDateString()}`}
            </Text>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!isTaskPastDeadline && (
            <Text
              size="xs"
              color={theme.colors.gray[7]}
              style={{ marginRight: 5 }}
            >
              {intl.formatMessage({ id: "due_in" })}
              {TimeDiffToDisplay(timeDiff)}
            </Text>
          )}
          {isTaskPastDeadline && (
            <Text
              size="xs"
              color={theme.colors.gray[7]}
              style={{ marginRight: 5 }}
            >
              {isTaskCompleted
                ? intl.formatMessage({ id: "completed" }) +
                  TimeDiffToDisplay(
                    new Date(event.event_start_time) - new Date()
                  ) +
                  " " +
                  intl.formatMessage({ id: "ago" })
                : intl.formatMessage({ id: "missed" }) +
                  TimeDiffToDisplay(timeDiff) +
                  " " +
                  intl.formatMessage({ id: "ago" })}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
