import React, { useEffect, useState } from "react";
import { Table, ActionIcon, Input } from "@mantine/core";
import { Eye, Search } from "tabler-icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import { PaginationItem } from "./PaginationItem";

const ClientTable = ({
  clients,
  columns,
  itemsPerPage = 40,
  actionsRow = false,
}) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnKey) => {
    if (sortColumn === columnKey) {
      // Reverse the direction if already sorting by this column
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set new column to sort by and default direction to asc
      setSortColumn(columnKey);
      setSortDirection("asc");
    }
  };

  const columnValue = (client, key) => {
    const column = columns.find((col) => col.key === key);
    return column && column.render(client);
  };

  const filteredClients = clients?.filter((client) =>
    columns.some((column) =>
      column.searchable
        ? columnValue(client, column.key)
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase())
        : false
    )
  );

  const sortedClients = filteredClients?.sort((a, b) => {
    const columnA = sortColumn && columnValue(a, sortColumn);
    const columnB = sortColumn && columnValue(b, sortColumn);

    if (columnA === columnB) {
      return 0;
    } else if (sortDirection === "asc") {
      return columnA < columnB ? -1 : 1;
    } else {
      return columnA > columnB ? -1 : 1;
    }
  });

  const totalPages = Math.ceil(sortedClients?.length / itemsPerPage);
  const startIndex = currentPage === 1 ? 0 : (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedClients = sortedClients?.slice(startIndex, endIndex);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  let i = 0;
  return (
    <div>
      <Input
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1);
        }}
        icon={<Search />}
        placeholder={intl.formatMessage({ id: "search" })}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{ marginBottom: "20px", justifyContent: "center" }}
        />
      )}
      <div style={{ overflowX: "auto" }}>
        <Table
          horizontalSpacing="sm"
          verticalSpacing="sm"
          striped
          highlightOnHover
          withBorder
          withColumnBorders
        >
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  onClick={() => {
                    if (column.sort == true || column.searchable == true)
                      handleSort(column.key);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {column.title && <FormattedMessage id={column.title} />}
                  {sortColumn === column.key && (
                    <span>{sortDirection === "asc" ? " 🔼" : " 🔽"}</span>
                  )}
                </th>
              ))}
              {actionsRow && <th></th>}
            </tr>
          </thead>
          <tbody>
            {displayedClients.map((client) => (
              <tr key={client.clientid ? client.clientid : ++i}>
                {columns.map((column) => (
                  <td key={column.key}>{column.render(client)}</td>
                ))}
                {actionsRow && (
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    {/* Example action icon */}
                    <a target="_blank" href={`/partner/${client.clientid}`}>
                      <ActionIcon color="green" size="lg" variant="light">
                        <Eye />
                      </ActionIcon>
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{ marginTop: "20px", justifyContent: "center" }}
        />
      )}
    </div>
  );
};

export default ClientTable;
